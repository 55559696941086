import * as Sentry from '@sentry/react';
import { startTransition, StrictMode } from 'react';

Sentry.init({
  dsn: 'https://25f220f6208545b33bf9cdda07974040@o4507914525802496.ingest.us.sentry.io/4507914531504128',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserSessionIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: [new RegExp('/customer/.*/order/.*'), new RegExp('/customer/.*/wizard/.*')],
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.0,
  attachStacktrace: true,
  enabled: !window.location.href.includes('localhost'),
});

import { RemixBrowser } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
